'use client'; // Error components must be Client Components

import { useEffect } from 'react';
import Link from "next/link"
import Navbar from "../components/navs/header";

import { Player } from "@lottiefiles/react-lottie-player";
import LabelBottomNavigation from 'components/navs/bottomnav';
import * as Sentry from "@sentry/nextjs";

export default function Error({ error, reset }) {

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  
  return (
    <>
      <Navbar />
      <div className="container flex flex-col items-center mt-12">
        <div className="h-72 w-96 relative">
          <Player
            autoplay
            loop
            src="https://assets2.lottiefiles.com/private_files/lf30_3X1oGR.json"
            style={{ height: '288px', width: '384px' }}
          >
          </Player>
        </div>
        <div className="inline-flex flex-col space-y-5 items-center mt-8 text-center">
          <p className="md:text-5xl text-3xl font-semibold text-black">Uh Oh! There was an error loading the page!</p>
          <p className="text-2xl font-medium text-black">Our team has been notified. Please try to refresh the page</p>
          <div className='flex items-center space-x-2'>
            <div onClick={() => reset()}
              className="flex items-center justify-center flex-1 px-16 pt-4 pb-3 bg-primary rounded-lg cursor-pointer">
              <p className="text-lg font-semibold text-white">Refresh</p>
            </div>
            <Link href="/" legacyBehavior>
              <div
                className="flex items-center justify-center flex-1 px-12 md:px-16 pt-4 pb-3 border border-primary rounded-lg cursor-pointer">
                <p className="text-lg font-semibold text-primary">Home</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <LabelBottomNavigation />
    </>
  );
}